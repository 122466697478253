<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-row dense>
      <v-col>
        <v-sheet
          color="#363636"
          elevation="1"
          height="100"
        >
          <v-img
            src="https://s3.amazonaws.com/s3.dealerinsights.net/ac_logo.png"
            height="100"
            width="200"
          />
        </v-sheet>
      </v-col>
    </v-row>
    <template v-if="!showInvalidResetDialog && !loading">
      <v-row
        dense
        justify="center"
      >
        <base-material-card
          icon="mdi-lock"
          title="Password Reset"
          color="#FF4081"
          style="background-color: #FAFAFA"
        >
          <v-col>
            <v-alert
              dense
              border="left"
              type="info"
            >
              To reset your password, enter your new password in the boxes below.
            </v-alert>
            <v-form
              ref="form"
              v-model="valid"
            >
              <v-card-text class="text-center">
                <v-text-field
                  v-model="password"
                  class="mb-8"
                  color="#90C74C"
                  label="Password..."
                  prepend-icon="mdi-lock-outline"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  required
                  aria-autocomplete="current-password"
                  :rules="[v => !!v || 'Password is required']"
                  @click:append="showPassword = !showPassword"
                />
                <v-text-field
                  v-model="verifyPassword"
                  class="mb-8"
                  color="#90C74C"
                  label="Verify Password..."
                  prepend-icon="mdi-lock-outline"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  required
                  aria-autocomplete="current-password"
                  :rules="[v => !!v || 'Password is required', passwordConfirmationRule || 'Passwords must match']"
                  @click:append="showPassword = !showPassword"
                />
              </v-card-text>
            </v-form>
          </v-col>
          <v-row
            dense
            justify="center"
          >
            <v-col>
              <div class="text-right py-2">
                <v-btn
                  elevation="1"
                  color="#90C74C"
                  :disabled="!valid"
                  @click="resetPassword()"
                >
                  Reset Password
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </base-material-card>
      </v-row>
    </template>
    <template v-if="showInvalidResetDialog">
      <v-row
        dense
        justify="center"
      >
        <v-col>
          <v-alert
            dense
            border="left"
            type="warning"
          >
            That reset request has either expired or is not valid.
          </v-alert>
        </v-col>
      </v-row>
    </template>
    <v-dialog
      v-model="showErrorDialog"
      persistent
      max-width="600"
      @keydown.esc="showErrorDialog = false"
    >
      <error-message
        :error="error"
        @closeErrorDialog="closeErrorDialog"
      />
    </v-dialog>
    <v-dialog
      v-model="showConfirmationDialog"
      max-width="600"
      hide-overlay
      persistent
    >
      <v-card
        color="#90C74C"
        dark
      >
        <v-card-text>
          <div class="white--text text-center">
            Your password has been reset. <br><br> <a
              href="/login"
            >Click here to login</a>.
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="#FF5E40"
        dark
      >
        <v-card-text>
          {{ loadingMessage }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  import { mapGetters } from 'vuex'
  import { formatterMixin } from '@/mixins/formatterMixin.js'
  import NetworkService from '@/services/NetworkService.js'
  import ErrorMessage from '@/components/ErrorMessage.vue'
  import ChatovateService from '@/services/ChatovateService.js'

  export default {
    name: 'PasswordReset',
    components: {
      ErrorMessage,
    },
    mixins: [formatterMixin],
    data () {
      return {
        showErrorDialog: false,
        loading: false,
        loadingMessage: 'Loading',
        error: '',
        token: null,
        email: null,
        showInvalidResetDialog: false,
        showConfirmationDialog: false,
        valid: false,
        password: '',
        verifyPassword: '',
        showPassword: false,
      }
    },
    computed: {
      ...mapGetters([]),
      passwordConfirmationRule () {
        return this.password === this.verifyPassword
      },
    },
    watch: {
    },
    created () {
      this.loadReset()
    },
    mounted () {
    },
    methods: {
      closeErrorDialog: function () {
        this.showErrorDialog = false
      },
      loadReset: function () {
        this.token = this.$route.query.token
        this.email = this.$route.query.email
        this.loadingMessage = 'Working..'
        this.loading = true
        ChatovateService.getPasswordReset(this.token, this.email)
          .then(response => {
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            if (error.response.status === 401) {
              this.showInvalidResetDialog = true
            } else {
              this.error = NetworkService.getErrorMessage(error)
              this.showErrorDialog = true
            }
          })
      },
      resetPassword: function () {
        this.loadingMessage = 'Working..'
        this.loading = true
        ChatovateService.updatePassword(this.token, this.email, this.password)
          .then(response => {
            this.loading = false
            this.showConfirmationDialog = true
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
    },
  }
</script>
